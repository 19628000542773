<template>
  <main class="container mx-auto flex flex-col items-center py-10 px-4">
    <h1 class="text-2xl font-bold mb-10">次回の学習予約</h1>
    <m-comment-panel class="mb-6">
      {{ comment }}
    </m-comment-panel>
    <div class="w-full mb-4">
      <div class="leading-7 text-sm font-medium text-gray-500">
        １回だけの学習予定
      </div>
      <div
        v-for="(row, rowIndex) in reservations"
        :key="`reservation${rowIndex}`"
        class="mx-auto mb-2 rounded-md py-2"
      >
        <div class="flex flex-wrap items-center">
          <m-text-field
            :name="`reservation${rowIndex}_date`"
            type="date"
            class="my-1 flex-1"
            :value="row.data.date"
            @input="row.data.date = $event"
          />
          <m-text-field
            name="time"
            type="time"
            placeholder="18:00"
            class="my-1 ml-2 flex-1"
            :value="row.data.time"
            @input="row.data.time = $event"
          />
          <m-button
            class="my-1 ml-4"
            color="primary-500"
            hover-color="primary-600"
            @click="removeReservationRow(rowIndex)"
          >
            <span>削除</span>
          </m-button>
        </div>
        <div
          v-if="
            row.data.date &&
            row.data.time &&
            !isFuture(row.data.date, row.data.time)
          "
          class="mt-1 text-red-500 text-xs"
        >
          未来の日時を選択してください
        </div>
      </div>
      <div class="flex flex-col items-center">
        <div
          class="flex items-center text-sm rounded-md bg-white text-gray-400 font-medium px-4 py-2 border-2 border-gray-200 cursor-pointer transition-colors duration-300 hover:text-primary-500 hover:border-primary-500"
          @click="addReservationRow"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            class="w-4 h-4 mr-2"
            viewBox="0 0 24 24"
          >
            <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>入力欄を追加する</span>
        </div>
      </div>
    </div>
    <div class="w-full mb-8">
      <div class="leading-7 text-sm font-medium text-gray-500">
        繰り返しの学習予定
      </div>
      <div
        v-for="(row, rowIndex) in regularlyReservations"
        :key="`regularReservation${rowIndex}`"
        class="flex flex-wrap items-center justify-start mx-auto mb-4 rounded-md py-2"
      >
        <div class="flex-1">
          <div class="flex">
            <m-select-box
              :key="`week${rowIndex}`"
              :name="`week${rowIndex}`"
              :options="weekOptions"
              :value="row.data.weekday"
              value-key="id"
              text-key="text"
              class="my-1 flex-1"
              @change="row.data.weekday = $event"
            />
            <m-text-field
              name="time"
              type="time"
              class="my-1 ml-2 flex-1"
              :value="row.data.time"
              @input="row.data.time = $event"
            />
          </div>
          <div class="flex flex-col lg:flex-row lg:h-12">
            <div class="flex items-center">
              <input
                :id="`useDeletionTime${rowIndex}`"
                v-model="row.data.useDeletionTime"
                type="checkbox"
                :name="`useDeletionTime${rowIndex}`"
                class="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
              />
              <label
                :for="`useDeletionTime${rowIndex}`"
                class="pl-2 cursor-pointer text-gray-500 text-sm"
              >
                繰り返し終了日を設定する
              </label>
            </div>
            <m-text-field
              v-if="row.data.useDeletionTime"
              :name="`deletionDay${rowIndex}`"
              type="date"
              :value="row.data.deletionTime"
              class="flex-1 ml-2 text-sm mt-2 lg:mt-0"
              @input="row.data.deletionTime = $event"
            />
          </div>
          <div
            v-if="
              row.data.deletionTime && !this.isFuture(row.data.deletionTime)
            "
            class="mt-1 text-red-500 text-xs"
          >
            未来の日時を選択してください
          </div>
        </div>
        <m-button
          class="ml-4"
          color="primary-500"
          hover-color="primary-600"
          @click="removeRegularlyReservationRow(rowIndex)"
        >
          <span>削除</span>
        </m-button>
      </div>
      <div class="flex flex-col items-center">
        <div
          class="flex items-center text-sm rounded-md bg-white text-gray-400 font-medium px-4 py-2 border-2 border-gray-200 cursor-pointer transition-colors duration-300 hover:text-primary-500 hover:border-primary-500"
          @click="addRegularlyReservationRow"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            class="w-4 h-4 mr-2"
            viewBox="0 0 24 24"
          >
            <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>入力欄を追加する</span>
        </div>
      </div>
    </div>
    <div class="flex justify-center w-full mb-6">
      <m-button :invalid="!isValidData" class="mr-4" @click="reserve">
        予約する
      </m-button>
      <m-button negative @click="toHome"> 予約せず終わる </m-button>
    </div>
  </main>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import {
  createRegularlyReservation,
  createReservation
} from "@/api/reservation";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MButton from "@/components/form/MButton.vue";
import MBaseButton from "@/components/MBaseButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import store from "@/store";
import { saveErrorLog } from "@/api/error";
import {
  ReservationEditingRow,
  RegularlyReservationEditingRow
} from "@/entities/reservation";

type WeekOption = {
  id: string;
  text: string;
};

@Options({
  components: {
    MCommentPanel,
    MButton,
    MBaseButton,
    MIcon,
    MTextField,
    MSelectBox
  }
})
export default class Reservation extends Vue {
  comment = "学習予約をすると予約日時の10分前にお知らせが届きます！";
  reservations: ReservationEditingRow[] = [];
  regularlyReservations: RegularlyReservationEditingRow[] = [];

  get weekOptions(): WeekOption[] {
    return ["日", "月", "火", "水", "木", "金", "土"].map(text => ({
      id: text,
      text: `毎週${text}曜日`
    }));
  }

  getWeekdayIndex(v: string): number {
    return ["日", "月", "火", "水", "木", "金", "土"].indexOf(v);
  }

  isValidDateString(value: string): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);
  }

  isValidTimeString(value: string): boolean {
    return /^\d{2}:\d{2}$/.test(value);
  }

  isFuture(dateString: string, timeString?: string): boolean {
    return dayjs(
      `${dateString} ${timeString ?? dayjs().format("hh:mm")}`
    ).isAfter(dayjs());
  }

  get isValidData(): boolean {
    if (
      this.reservations.length === 0 &&
      this.regularlyReservations.length === 0
    )
      return false;
    return (
      this.reservations.every(
        ({ data }) =>
          this.isValidDateString(data.date) &&
          this.isValidTimeString(data.time) &&
          this.isFuture(data.date, data.time)
      ) &&
      this.regularlyReservations.every(
        ({ data }) =>
          data.weekday &&
          this.isValidTimeString(data.time) &&
          (!data.useDeletionTime ||
            (this.isValidDateString(data.deletionTime ?? "") &&
              this.isFuture(data.deletionTime!)))
      )
    );
  }

  addReservationRow() {
    this.reservations = [
      ...this.reservations,
      {
        data: {
          date: "",
          time: ""
        }
      }
    ];
  }

  removeReservationRow(index: number) {
    this.reservations = this.reservations.filter((_, i) => i !== index);
  }

  addRegularlyReservationRow() {
    this.regularlyReservations = [
      ...this.regularlyReservations,
      {
        data: {
          type: "week",
          time: "",
          useDeletionTime: false,
          weekday: "",
          deletionTime: ""
        }
      }
    ];
  }

  removeRegularlyReservationRow(index: number) {
    this.regularlyReservations = this.regularlyReservations.filter(
      (_, i) => i !== index
    );
  }

  toHome() {
    this.$router.push("/");
  }

  async reserve() {
    if (!this.isValidData || !store.state.student) {
      return;
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "予約登録中...");

    const promises = [] as Promise<void>[];
    this.reservations.forEach(({ data }) => {
      const reserveAt = dayjs(`${data.date} ${data.time}`).locale("ja");
      store.state.student &&
        promises.push(
          createReservation(store.state.student.ref, reserveAt.unix())
        );
    });
    this.regularlyReservations.forEach(({ data }) => {
      if (!data.weekday) return;
      const targetDayIndex = this.getWeekdayIndex(data.weekday);
      const [hh, mm] = data.time.split(":").map(v => +v);
      let reservedNextAt = dayjs()
        .locale("ja")
        .hour(hh)
        .minute(mm)
        .second(0)
        .day(targetDayIndex);
      if (dayjs().isAfter(reservedNextAt)) {
        // 必ず未来日になるように調整
        reservedNextAt = reservedNextAt.add(7, "day");
      }

      const deletionTime = data.useDeletionTime
        ? dayjs(data.deletionTime).unix()
        : undefined;
      store.state.student &&
        promises.push(
          createRegularlyReservation(
            store.state.student.ref,
            reservedNextAt.unix(),
            "week",
            reservedNextAt.format("HH:mm"),
            reservedNextAt.day(),
            reservedNextAt.date(),
            deletionTime
          )
        );
    });

    Promise.all(promises)
      .then(() => {
        alert("学習予定を登録しました");
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        this.$router.push("/analyze/future");
      })
      .catch(async e => {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert(
          `学習予約の登録に失敗しました。時間をあけてから再度実行してみてください\n\n${e}`
        );
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to create reservation"
        );
      });
  }

  created() {
    const fromReflection = this.$route.query.reflection;
    if (!fromReflection) {
      return;
    }
    this.comment =
      "続けて、次回の学習予約をしよう！予約日時 10 分前にお知らせします！\n（開室前は、タイムキーパーが起動します）";
  }
}
</script>
