
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import {
  createRegularlyReservation,
  createReservation
} from "@/api/reservation";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MButton from "@/components/form/MButton.vue";
import MBaseButton from "@/components/MBaseButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import store from "@/store";
import { saveErrorLog } from "@/api/error";
import {
  ReservationEditingRow,
  RegularlyReservationEditingRow
} from "@/entities/reservation";

type WeekOption = {
  id: string;
  text: string;
};

@Options({
  components: {
    MCommentPanel,
    MButton,
    MBaseButton,
    MIcon,
    MTextField,
    MSelectBox
  }
})
export default class Reservation extends Vue {
  comment = "学習予約をすると予約日時の10分前にお知らせが届きます！";
  reservations: ReservationEditingRow[] = [];
  regularlyReservations: RegularlyReservationEditingRow[] = [];

  get weekOptions(): WeekOption[] {
    return ["日", "月", "火", "水", "木", "金", "土"].map(text => ({
      id: text,
      text: `毎週${text}曜日`
    }));
  }

  getWeekdayIndex(v: string): number {
    return ["日", "月", "火", "水", "木", "金", "土"].indexOf(v);
  }

  isValidDateString(value: string): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);
  }

  isValidTimeString(value: string): boolean {
    return /^\d{2}:\d{2}$/.test(value);
  }

  isFuture(dateString: string, timeString?: string): boolean {
    return dayjs(
      `${dateString} ${timeString ?? dayjs().format("hh:mm")}`
    ).isAfter(dayjs());
  }

  get isValidData(): boolean {
    if (
      this.reservations.length === 0 &&
      this.regularlyReservations.length === 0
    )
      return false;
    return (
      this.reservations.every(
        ({ data }) =>
          this.isValidDateString(data.date) &&
          this.isValidTimeString(data.time) &&
          this.isFuture(data.date, data.time)
      ) &&
      this.regularlyReservations.every(
        ({ data }) =>
          data.weekday &&
          this.isValidTimeString(data.time) &&
          (!data.useDeletionTime ||
            (this.isValidDateString(data.deletionTime ?? "") &&
              this.isFuture(data.deletionTime!)))
      )
    );
  }

  addReservationRow() {
    this.reservations = [
      ...this.reservations,
      {
        data: {
          date: "",
          time: ""
        }
      }
    ];
  }

  removeReservationRow(index: number) {
    this.reservations = this.reservations.filter((_, i) => i !== index);
  }

  addRegularlyReservationRow() {
    this.regularlyReservations = [
      ...this.regularlyReservations,
      {
        data: {
          type: "week",
          time: "",
          useDeletionTime: false,
          weekday: "",
          deletionTime: ""
        }
      }
    ];
  }

  removeRegularlyReservationRow(index: number) {
    this.regularlyReservations = this.regularlyReservations.filter(
      (_, i) => i !== index
    );
  }

  toHome() {
    this.$router.push("/");
  }

  async reserve() {
    if (!this.isValidData || !store.state.student) {
      return;
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "予約登録中...");

    const promises = [] as Promise<void>[];
    this.reservations.forEach(({ data }) => {
      const reserveAt = dayjs(`${data.date} ${data.time}`).locale("ja");
      store.state.student &&
        promises.push(
          createReservation(store.state.student.ref, reserveAt.unix())
        );
    });
    this.regularlyReservations.forEach(({ data }) => {
      if (!data.weekday) return;
      const targetDayIndex = this.getWeekdayIndex(data.weekday);
      const [hh, mm] = data.time.split(":").map(v => +v);
      let reservedNextAt = dayjs()
        .locale("ja")
        .hour(hh)
        .minute(mm)
        .second(0)
        .day(targetDayIndex);
      if (dayjs().isAfter(reservedNextAt)) {
        // 必ず未来日になるように調整
        reservedNextAt = reservedNextAt.add(7, "day");
      }

      const deletionTime = data.useDeletionTime
        ? dayjs(data.deletionTime).unix()
        : undefined;
      store.state.student &&
        promises.push(
          createRegularlyReservation(
            store.state.student.ref,
            reservedNextAt.unix(),
            "week",
            reservedNextAt.format("HH:mm"),
            reservedNextAt.day(),
            reservedNextAt.date(),
            deletionTime
          )
        );
    });

    Promise.all(promises)
      .then(() => {
        alert("学習予定を登録しました");
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        this.$router.push("/analyze/future");
      })
      .catch(async e => {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert(
          `学習予約の登録に失敗しました。時間をあけてから再度実行してみてください\n\n${e}`
        );
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to create reservation"
        );
      });
  }

  created() {
    const fromReflection = this.$route.query.reflection;
    if (!fromReflection) {
      return;
    }
    this.comment =
      "続けて、次回の学習予約をしよう！予約日時 10 分前にお知らせします！\n（開室前は、タイムキーパーが起動します）";
  }
}
